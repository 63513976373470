.fancytype {
    border-right: solid 3px rgba(0,255,0,.75);
    white-space: nowrap;
    overflow: hidden;    
    font-family: 'Source Code Pro', monospace;  
    font-size: 28px;
    color: rgba(255,255,255,.70);
    margin: 0 auto;
}

/* Animation */
.fancytype {
    animation: animated-text 4s steps(10,end) 0.5s 1 normal both,
               animated-cursor 600ms steps(10,end) 100;
}

/* text animation */

@keyframes animated-text{
    from{width: 0%;}
    to{width: 50%;}
}

/* cursor animations */

@keyframes animated-cursor{
    from{border-right-color: transparent;}
    to{border-right-color: rgba(0,255,0,.75);}
}

.fancytyper {
    display: inline;
}
