.rotatebutton {
    position: relative;
    top: 50%;
    transform: translate(0, -50%);
    display: inline-block;
}

.turndown {
    transition: 0.2s ease-in-out !important;
    transform: rotate(-0deg) !important;
}

.turnup {
    transition: 0.2s ease-in-out !important;
    transform: rotate(-90deg) !important;
}

.project {
    margin: 10px auto !important;
    cursor: pointer;
    color: inherit;
    background-color: inherit;
    font: inherit;
    text-align: left;
    margin: 1vw;
    width: 90%;
    /*height: 18vw;*/
    border-style: solid;
    border-radius: 3px;
    border-width: 2px;
    /*box-shadow: 5px 5px #181a1f;*/
    
}

.cardtext {
    padding-left: 1vw;
    vertical-align: middle;
    font-size: 2vw;
}

.readmetext {
    padding-left: 1vw;
    vertical-align: middle;
    font-size: 1.5vw;
}
