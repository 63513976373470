.main {
    overflow: hidden;
    position: absolute;
    margin: 0px;
    height: 100%;
    width: 100%;
    font-family: 'Source Code Pro', monospace;  
}

.tall {
    height: 100%;
}

a {
    color: orange;
}

img {
    width: 95%;
}
